import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
      redirect: "/server",
      component: () => import("@/components/Home"),
      children: [
        {
          path: "/server",
          name: "server",
          component: () => import("@/components/Server"),
          meta: { title: "公司介绍" },
        },
        {
          path: "/index",
          name: "index",
          component: () => import("@/components/Index"),
          meta: { title: "产品服务" },
        },
        {
          path: "/contact",
          name: "contact",
          component: () => import("@/components/Contact"),
          meta: { title: "联系方式" },
        },
        {
          path: "/fwxy",
          name: "fwxy",
          component: () => import("@/components/fwxy"),
          meta: { title: "用户服务协议" },
        },
        {
          path: "/yszc",
          name: "yszc",
          component: () => import("@/components/yszc"),
          meta: { title: "用户隐私政策" },
        },
      ],
    },
  ],
});
