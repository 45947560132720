//store中的index.js
import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    activeIndex: "",
  },
  mutations: {
    setActiveIndex2(state, e) {
      state.activeIndex = e;
      window.localStorage.setItem("activeIndex2", e);
    },
  },
});
