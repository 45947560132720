//接口二次封装
import axios from "axios";
import { getToken } from "./utils/setToken";
import { Message } from "element-ui";
const service = axios.create({
  baseURL: '/api', //baseURL会自动加在请求地址上
  timeout: 30000,
});

//添加请求拦截器
service.interceptors.request.use(
  (config) => {
    //在请求前获取并设置token
    config.headers["authorization"] = getToken("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//添加响应拦截器
service.interceptors.response.use(
  (response) => {
    //对响应数据进行处理
    let { code, info } = response.data;
    if (code !== 1) {
      // Message({ message: info || "error", type: "warning" });
    }
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
