import Vue from "vue";
import App from "./App.vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "font-awesome/css/font-awesome.min.css";
import "@/assets/css/common.css";
import service from "@/service";
import router from "@/router";

import locale from "element-ui/lib/locale/lang/en";

import store from "@/store";

import "@/utils/rem";
Vue.use(ElementUI, { locale });
Vue.prototype.service = service; //挂载到原型 就可以全局使用

Vue.prototype.language = navigator.language || navigator.userLanguage;

Vue.prototype.isMobile = function () {
  const userAgentInfo = navigator.userAgent;
  const mobileAgents = [
    "Android",
    "iPhone",
    "SymbianOS",
    "Windows Phone",
    "iPad",
    "iPod",
  ];
  for (let i = 0; i < mobileAgents.length; i++) {
    if (userAgentInfo.indexOf(mobileAgents[i]) > 0) {
      return true; // 是移动设备
    }
  }
  return false; // 是PC设备
};

Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  console.log("to", to);
  if (to.meta && to.meta.title) {
    localStorage.setItem("activeIndex", to.fullPath);
    if (to.meta.title == "公司介绍") {
      document.title = "慧意计算机";
    } else {
      document.title = to.meta.title + "-慧意计算机";
    }
    //document.title = to.meta.title;
  }
  window.scrollTo(0, 0);
  next();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
